<template>
  <div>
    <v-dialog v-model="editUserDialog" persistent width="650px">
      <v-card class="overflow--hidden">
        <v-toolbar dense class="elevation-0 gradient-bg white--text">
          <v-toolbar-title>Edit Member</v-toolbar-title>
          <v-spacer />

          <v-icon @click="close_dialog()" class="white--text mr-n2"
            >mdi-close</v-icon
          >
        </v-toolbar>

        <v-form ref="form">
          <v-row no-gutters class="mt-n3">
            <v-col cols="5" md="5" sm="5" lg="5" xl="5" xs="5">
              <v-text-field
                label="Member Id *"
                v-model="member_id"
                dense
                class="mt-5 ml-4"
                :rules="member_id_rules"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="7" md="7" sm="7" lg="7" xl="7" xs="7">
              <v-text-field
                label="Member Name *"
                v-model="member_name"
                dense
                :rules="member_name_rules"
                outlined
                class="mt-5 mr-4 ml-2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-4 mt-n2" no-gutters>
            <v-col cols="12" md="5" sm="12" lg="5" xl="5">
              <v-autocomplete
                label="Country Code "
                v-model="country_code"
                :items="CountryList"
                item-value="phonecode"
                item-text="name"
                dense
                outlined
                class="mr-2"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ item.flag }} ({{
                    item.phonecode.toString().includes("+") == false
                      ? "+" + item.phonecode
                      : item.phonecode
                  }})
                </template>
                <template slot="item" slot-scope="{ item }">
                  {{ item.flag }} {{ item.name }} ({{ item.phonecode }})
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" lg="6" xl="6" md="6">
              <v-text-field
                v-model="contact_number"
                dense
                outlined
                label="Contact Number "
                :rules="
                  $store.getters.get_org_details[0].is_phone_number_mandatory ==
                  true
                    ? contact_number_rules_requried
                    : contact_number_rules
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" sm="12" lg="1" xl="1">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ml-2" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span class="white--text"
                  >An SMS/text invite will be sent to this number, along with a
                  URL to download the MyPrezence app from the Google/Apple
                  Store.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-n2" no-gutters>
            <v-col cols="12" md="7" sm="7" lg="7" xl="7">
              <v-text-field
                label="E-Mail"
                v-model="email"
                dense
                :rules="email_rules"
                :disabled="
                  userDetailsObj.user_type == 'ADMIN' ||
                  userDetailsObj.user_type == 'OWNER'
                "
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="5" sm="5" lg="5" xl="5">
              <v-select
                v-model="shiftName"
                :items="ShiftItems"
                dense
                outlined
                :rules="[(v) => !!v || 'required']"
                label="Select Shift *"
                item-text="workshift_name"
                item-value="workshift_id"
                class="ml-2"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-n2" no-gutters>
            <v-col cols="4" md="4" sm="4" lg="4" xl="4" xs="4">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="birthdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="birthdate"
                    label="Date Of Birth"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    dense
                    color="#f4813f"
                    outlined
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="birthdate"
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                  color="#f4813f"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(birthdate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="4" md="4" sm="4" lg="4" xl="4" xs="4">
              <v-autocomplete
                label="Select Desgination "
                v-model="DesginationName"
                :items="DesginationItems"
                dense
                outlined
                item-text="designation_name"
                item-value="designation_id"
                :persistent-hint="true"
                class="ml-4"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" md="4" sm="4" lg="4" xl="4" xs="4">
              <v-autocomplete
                label="Employee Type"
                v-model="employeetypeName"
                :items="employeetypeItems"
                dense
                outlined
                item-text="employee_type"
                item-value="employee_type_id"
                :persistent-hint="true"
                class="ml-4"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" md="4" sm="4" lg="4" xl="4" xs="4">
              <v-autocomplete
                label="Home Location"
                v-model="homeLoaction"
                :items="filterhomnelocations"
                dense
                outlined
                :rules="[(v) => !!v || 'required']"
                item-text="location_name"
                item-value="location_name"
                :persistent-hint="true"
                class=""
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" md="4" sm="4" lg="4" xl="4" xs="4">
              <v-dialog
                ref="dialog1"
                v-model="modaljoin"
                :return-value.sync="joindate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="joindate"
                    label="Date Of Joining"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    dense
                    color="#f4813f"
                    class="ml-2"
                    outlined
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="joindate"
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                  color="#f4813f"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modaljoin = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog1.save(joindate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="mt-n7 mx-4" no-gutters>
            <v-col cols="12" md="1" sm="12" lg="1" xl="1">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn class="mt-3" v-on="on" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span
                  class="white--text"
                  v-if="$store.getters.get_org_details[0].is_qr_enabled == true"
                >
                  Enable, to give users access to the Simple Scan feature
                </span>
                <span class="white--text" v-else>
                  Your Organization has disabled Simple Scan
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="11" sm="11" lg="11" xl="11" xs="11">
              <v-switch
                v-model="switch_simplescan"
                :disabled="
                  $store.getters.get_org_details[0].is_simple_scan_enabled ==
                  false
                "
                dense
                :label="`Simple : ${
                  switch_simplescan.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                :color="
                  $store.getters.get_org_details[0].is_simple_scan_enabled ==
                    false && switch_simplescan == true
                    ? 'grey'
                    : ''
                "
              >
              </v-switch>
            </v-col>
          </v-row>
          <!-- <v-span>start qr</v-span> -->
          <v-row class="mt-n8 mx-4" no-gutters>
            <v-col cols="12" md="1" sm="12" lg="1" xl="1">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn class="mt-3" v-on="on" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span
                  class="white--text"
                  v-if="$store.getters.get_org_details[0].is_qr_enabled == true"
                  >QR Scan is a default feature that cannot be disabled
                </span>
                <span class="white--text" v-else>
                  Your Organization has disabled Qr swipe
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="11" sm="11" lg="11" xl="11">
              <v-switch
                v-model="switch_QR"
                disabled
                dense
                :label="`QR Scan : ${
                  switch_QR.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                :color="
                  $store.getters.get_org_details[0].is_qr_enabled == false &&
                  switch_QR == true
                    ? 'grey'
                    : ''
                "
              >
              </v-switch>
              <!-- :disabled="
                  this.$store.getters.get_org_details[0].is_qr_enabled == false
                " -->
            </v-col>
          </v-row>
          <!-- <v-span>end qr</v-span> -->

          <!-- <v-span>start face</v-span> -->
          <v-row class="mt-n7 mx-4" no-gutters>
            <v-col cols="12" md="1" sm="12" lg="1" xl="1">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn class="mt-3" v-on="on" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span
                  class="white--text"
                  v-if="
                    $store.getters.get_org_details[0].is_face_enabled == true
                  "
                  >Enable, to give users access to the Face Scan feature
                </span>
                <span class="white--text" v-else>
                  Your Organization has disabled Face Scan
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="11" sm="11" lg="11" xl="11">
              <v-switch
                v-model="switch_face"
                :disabled="
                  this.$store.getters.get_org_details[0].is_face_enabled ==
                  false
                "
                dense
                :label="`Face : ${
                  switch_face.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                :color="
                  $store.getters.get_org_details[0].is_face_enabled == false &&
                  switch_face == true
                    ? 'grey'
                    : ''
                "
              >
              </v-switch>
            </v-col>
          </v-row>
          <!-- <v-span>end face</v-span> -->
          <!-- <v-span>start beacon</v-span> -->
          <v-row class="mt-n7 mx-4" no-gutters>
            <v-col cols="12" md="1" sm="12" lg="1" xl="1">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn class="mt-3" v-on="on" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span
                  class="white--text"
                  v-if="
                    $store.getters.get_org_details[0].is_beacon_enabled == true
                  "
                  >Enable, to give users access to the Beacon Swipes feature
                </span>
                <span class="white--text" v-else>
                  Your Organization has disabled Beacon swipe
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="11" sm="11" lg="11" xl="11">
              <v-switch
                v-model="switch_beacon"
                :disabled="
                  this.$store.getters.get_org_details[0].is_beacon_enabled ==
                  false
                "
                dense
                :label="`Beacon  : ${
                  switch_beacon.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                :color="
                  $store.getters.get_org_details[0].is_beacon_enabled ==
                    false && switch_beacon == true
                    ? 'grey'
                    : ''
                "
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row class="mt-n7 mx-4" no-gutters>
            <v-col cols="12" md="1" sm="12" lg="1" xl="1">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn class="mt-3" v-on="on" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span
                  class="white--text"
                  v-if="
                    $store.getters.get_org_details[0].is_proxy_enabled == true
                  "
                  >Enable, to give users access to the QR Scan feature
                </span>
                <span class="white--text" v-else>
                  Your Organization has disabled Proxy Scan
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="11" sm="11" lg="11" xl="11" xs="11">
              <v-switch
                v-model="switch_proxy"
                :disabled="
                  this.$store.getters.get_org_details[0].is_proxy_enabled ==
                  false
                "
                dense
                :label="`Proxy : ${
                  switch_proxy.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                :color="
                  $store.getters.get_org_details[0].is_proxy_enabled == false &&
                  switch_proxy == true
                    ? 'grey'
                    : ''
                "
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row class="mt-n7 mx-4" no-gutters>
            <v-col cols="12" md="1" sm="12" lg="1" xl="1">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn class="mt-3" v-on="on" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span
                  class="white--text"
                  v-if="
                    $store.getters.get_org_details[0].is_tracking_mandatory ==
                    true
                  "
                  >Enable, to give users access to the Live Location Tracking
                  feature
                </span>
                <span class="white--text" v-else>
                  Your Organization has disabled Live Location Tracking
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="11" sm="11" lg="11" xl="11">
              <v-switch
                v-model="switch_livetracking"
                :disabled="
                  $store.getters.get_org_details[0].is_tracking_mandatory ==
                  false || $store.getters.get_org_details[0].is_tracking_mandatory ==
                  null 
                "
                dense
                :label="`Live Location Tracking : ${
                  switch_livetracking.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                :color="
                  $store.getters.get_org_details[0].is_tracking_mandatory ==
                    false && switch_livetracking == true
                    ? 'grey'
                    : ''
                "
              >
              </v-switch>
            </v-col>
          </v-row>
          <!-- <v-span>end beacon</v-span> -->

          <!-- <v-row class="mt-n7 mx-4" no-gutters>
            <v-col cols="12" md="1" sm="12" lg="1" xl="1">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn class="mt-3" v-on="on" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span class="white--text"
                  >Enable, to allow user submit their current location through
                  WhatsApp
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="11" sm="11" lg="11" xl="11">
              <v-switch
                v-model="switch_whatsapp"
                dense
                :label="`WhatsApp Scan (send current location to 99807 24070) : ${
                  switch_whatsapp.toString() === 'true' ? 'Yes' : 'No'
                }`"
                :disabled="
                  this.$store.getters.get_org_details[0].is_whatsapp == false
                "
                inset
              >
              </v-switch>
            </v-col>
          </v-row> -->
          <v-card-text class="mt-n5"><v-divider /></v-card-text>

          <v-row class="mt-n7 mx-4" no-gutters>
            <v-col cols="12" md="1" sm="12" lg="1" xl="1">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn class="mt-3" v-on="on" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span class="white--text"
                  >Provide access to Face Scan Anywhere - outside the
                  organization’s geofenced Locations
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="11" sm="11" lg="11" xl="11">
              <v-radio-group v-model="switch_scananywhere">
                <v-radio
                  :label="`Scan Anywhere (outside the organization’s geofenced locations) : ${
                    switch_scananywhere.toString() === 'radio-1' ? 'Yes' : 'No'
                  }`"
                  value="radio-1"
                ></v-radio>
                <v-radio
                  label="Scan within organizations locations (any geo-fenced locations)"
                  value="ANY_LOCATIONS"
                ></v-radio>

                <v-radio
                  label="Assign only one location to user (choose the location)"
                  value="radio-2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mt-n7 mx-4" no-gutters>
            <v-col cols="6" md="5" sm="5" lg="5" xl="5">
              <!-- <div v-if="switch_scananywhere == false"> -->
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-autocomplete
                      label="Select Location *"
                      v-model="filterlocation"
                      :items="filterlocations"
                      dense
                      v-on="on"
                      v-if="switch_scananywhere == 'radio-2'"
                      item-text="location_name"
                      item-value="location_id"
                      outlined
                      class="ml-12 mt-8 mr-6"
                    >
                    </v-autocomplete>
                  </div>
                </template>
                <span class="white--text">
                  Please uncheck scan anywhere before selecting location</span
                >
              </v-tooltip>
              <!-- </div> -->
            </v-col>
          </v-row>

          <v-card-text align="center">
            <v-btn
              :loading="isLoading"
              @click="validate_edit_member()"
              class="gradient-bg white--text"
              ><v-icon class="mr-2">mdi-pencil</v-icon>Save Changes
            </v-btn>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { Country } from "country-state-city";
import { API, graphqlOperation } from "aws-amplify";
import { UpdateMemberDetails } from "@/graphql/mutations.js";
import { Getshiftwork } from "@/mixins/GetWorkshift.js";
import { GetDesgination } from "@/mixins/GetDesgination.js";
import { GetLocation } from "@/mixins/GetLocationList.js";
import { GetEmployeetype } from "@/mixins/GetEmployeetype.js";
export default {
  props: {
    editUserDialog: Boolean,
    userDetailsObj: Object,
  },
  mixins: [Getshiftwork, GetLocation, GetDesgination, GetEmployeetype],
  data: () => ({
    selectedOption: "",
    row: "ANY_LOCATIONS",
    filterlocation: "",
    filterlocations: [],
    birthdate: "",
    modal: false,
    shiftName: "",
    ShiftItems: [],
    workshiftList: [],
    DesginationName: "",
    DesginationItems: [],
    employeetypeName: "",
    homeLoaction: "",
    filterhomnelocations: [],
    employeetypeItems: [],
    searchF: null,
    member_id: "",
    member_name: "",
    email: "",
    modaljoin: false,
    joindate: "",
    country_code: "",
    contact_number: "",
    switch_reswipe: false,
    switch_whatsapp: false,
    switch_proxy: false,
    switch_simplescan: true,
    switch_beacon: false,
    switch_livetracking: false,
    switch_QR: true,
    switch_face: false,
    switch_scananywhere: false,
    CountryList: [],
    array1: [],
    filterlocationsres: [],
    isLoading: false,
    member_id_rules: [
      (v) => v.trim() != "" || "Member Id cannot be empty",
      (v) =>
        /^[a-z\sA-Z0-9]{0,10}$/.test(v) ||
        "Member Id must be between 1 & 10 characters and Should not contain special characters",
    ],
    member_name_rules: [
      (x) =>
        /^[a-z\sA-Z]{3,30}$/.test(x) ||
        "Name must be between 3 & 30 characters and Should not contain special characters",
      (x) => x.trim() != "" || "Member Name cannot be empty",
    ],
    country_code_rules: [(x) => x.trim() != "" || "Select Country Code"],
    contact_number_rules: [
      (x) =>
        !x ||
        (/^[0-9]+$/.test(x) && x.length >= 8 && x.length <= 15) ||
        "Contact Number must contain only numbers",
      // (x) => x.trim() != "" || "Contact Number cannot be empty",
      // (v) => /^[0-9]{8,12}$/.test(v) || "Contact Number must be valid",
    ],
    contact_number_rules_requried: [
      (x) =>
        !x ||
        (/^[0-9]+$/.test(x) && x.length >= 8 && x.length <= 15) ||
        "Contact Number must contain only numbers",
      (x) => x.trim() != "" || "Contact Number cannot be empty",
      // (v) => /^[0-9]{7,15}$/.test(v) || "Contact Number must be valid",
    ],
    email_rules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],
    user_id: "",
  }),
  watch: {
    async editUserDialog(val) {
      if (val == true) {
        await this.Getshiftwork();
        await this.GetLocation();
        await this.GetDesgination();
        await this.GetEmployeetype();
        await this.fetchdetails();
        this.birthdate = "";
        this.joindate = "";
        this.homeLoaction =
          this.userDetailsObj.home_location_name == null
            ? ""
            : this.userDetailsObj.home_location_name;
        this.shiftName = this.userDetailsObj.workshift_id;
        this.member_id = this.userDetailsObj.employee_id;
        this.member_name = this.userDetailsObj.user_name;
        this.country_code =
          this.userDetailsObj.user_country_code == null
            ? ""
            : this.userDetailsObj.user_country_code;
        this.contact_number = this.userDetailsObj.user_contact_number;
        // console.log(this.$store.getters.get_org_details[0]);
        this.email =
          this.userDetailsObj.user_email_id == undefined ||
          this.userDetailsObj.user_email_id == "null" ||
          this.userDetailsObj.user_email_id == null
            ? ""
            : this.userDetailsObj.user_email_id;
        this.switch_reswipe = this.userDetailsObj.is_reswipe_enabled;
        this.switch_whatsapp = this.userDetailsObj.is_whatsapp;
        this.switch_beacon = this.userDetailsObj.is_beacon_enabled;

        this.switch_livetracking =
          this.userDetailsObj.is_tracking_mandatory == undefined ||
          this.userDetailsObj.is_tracking_mandatory == null
            ? false
            : this.userDetailsObj.is_tracking_mandatory;
        this.switch_QR = this.userDetailsObj.is_qr_enabled;
        this.switch_simplescan =
          this.userDetailsObj.is_simple_scan_enabled == undefined ||
          this.userDetailsObj.is_simple_scan_enabled == null
            ? false
            : this.userDetailsObj.is_simple_scan_enabled;
        // console.log(this.userDetailsObj);
        this.switch_face = this.userDetailsObj.is_face_enabled;
        this.switch_proxy = this.userDetailsObj.is_proxy_enabled;
        this.user_id = this.userDetailsObj.user_id;
        this.DesginationName =
          this.userDetailsObj.designation == null
            ? ""
            : this.userDetailsObj.designation;
        this.employeetypeName =
          this.userDetailsObj.employee_type == null
            ? ""
            : this.userDetailsObj.employee_type;

        this.switch_scananywhere = [undefined, null].includes(
          this.userDetailsObj.is_scan_any_where
        )
          ? false
          : this.userDetailsObj.is_scan_any_where;

        this.switch_QR = [undefined, null].includes(
          this.userDetailsObj.is_qr_enabled
        )
          ? false
          : this.userDetailsObj.is_qr_enabled;
        this.switch_simplescan = [undefined, null].includes(
          this.userDetailsObj.is_simple_scan_enabled
        )
          ? false
          : this.userDetailsObj.is_simple_scan_enabled;

        this.switch_face = [undefined, null].includes(
          this.userDetailsObj.is_face_enabled
        )
          ? false
          : this.userDetailsObj.is_face_enabled;
        this.switch_proxy = [undefined, null].includes(
          this.userDetailsObj.is_proxy_enabled
        )
          ? false
          : this.userDetailsObj.is_proxy_enabled;
        this.switch_whatsapp = [undefined, null].includes(
          this.userDetailsObj.is_whatsapp
        )
          ? false
          : this.userDetailsObj.is_whatsapp;

        this.switch_beacon = [undefined, null].includes(
          this.userDetailsObj.is_beacon_enabled
        )
          ? false
          : this.userDetailsObj.is_beacon_enabled;

        if (
          this.userDetailsObj.location_id == "ANY_LOCATIONS" &&
          this.userDetailsObj.is_scan_any_where == false
        ) {
          this.switch_scananywhere = "ANY_LOCATIONS";
        } else if (this.userDetailsObj.location_id != "ANY_LOCATIONS") {
          this.switch_scananywhere = "radio-2";
          this.filterlocation = this.userDetailsObj.location_id;
        } else if (
          this.userDetailsObj.location_id == "ANY_LOCATIONS" &&
          this.userDetailsObj.is_scan_any_where == true
        ) {
          this.switch_scananywhere = "radio-1";
        }

        this.CountryList = Country.getAllCountries();
        var self = this;
        self.CountryList.forEach(function (CountryIDX) {
          if (CountryIDX.phonecode == self.country_code.split("+")[1]) {
            self.country_code = CountryIDX.phonecode;
          }
        });
        // alert(this.userDetailsObj.date_of_birth)
        this.birthdate =
          this.userDetailsObj.date_of_birth == null
            ? ""
            : this.newdateformate(this.userDetailsObj.date_of_birth);
        this.joindate =
          this.userDetailsObj.joining_date == null
            ? ""
            : this.newdateformate(this.userDetailsObj.joining_date);

        // console.log(this.userDetailsObj.joining_date, "joindate");

        // alert( this.birthdate)
      }
      // contact_number(val);
      // {
      //   console.log(val);
      // }
    },
    // switch_scananywhere(val) {
    //   if (val == true) {
    //     this.filterlocation = "";
    //   } else {
    //     for (const item of this.filterlocations) {
    //       if (item.location_id == this.userDetailsObj.location_id) {
    //         this.filterlocation = item;
    //       }
    //     }
    //   }
    // },
  },

  methods: {
    getRules() {
      // Check if ContNum is not an empty string, then apply rules
      return this.contact_number !== null || this.contact_number !== ""
        ? [(v) => !!v || "Country Code is required"]
        : [];
    },
    fetchdetails() {
      this.GetWorkshiftitems.forEach((element) => {
        this.ShiftItems.push({
          workshift_name: element.workshift_name,
          workshift_id: element.workshift_id,
        });
      });
      this.GetLocationitems.forEach((element) => {
        this.filterlocations.push({
          location_name: element.loc_name,
          location_id: element.loc_id,
        });
        this.filterhomnelocations.push({
          location_name: element.loc_name,
          location_id: element.loc_id,
        });
      });
      this.GetDesginationitems.forEach((element) => {
        this.DesginationItems.push({
          designation_name: element.designation_name,
          designation_id: element.designation_name,
        });
      });
      this.DesginationItems.sort((a, b) =>
        a.designation_name.localeCompare(b.designation_name)
      );
      this.GetitemsEmployee.forEach((element) => {
        this.employeetypeItems.push({
          employee_type: element.employee_type,
          employee_type_id: element.employee_type,
        });
      });
      this.employeetypeItems.sort((a, b) =>
        a.employee_type.localeCompare(b.employee_type)
      );
    },
    validate_edit_member() {
      if (this.$refs.form.validate() && this.switch_scananywhere == "radio-1") {
        // if (
        //   this.switch_beacon == false &&
        //   this.switch_face == false &&
        //   this.switch_QR == false
        // ) {
        //   this.$store.commit("snackbar/showMessage", {
        //     content: "Among Beacon ,Face and QR one should be enabled",
        //     color: "red",
        //   });
        // } else {
        this.edit_member();
        // }
      } else if (
        this.$refs.form.validate() &&
        this.switch_scananywhere != "radio-1" &&
        this.switch_scananywhere == "ANY_LOCATIONS"
      ) {
        // if (
        //   this.switch_beacon == false &&
        //   this.switch_face == false &&
        //   this.switch_QR == false
        // ) {
        //   this.$store.commit("snackbar/showMessage", {
        //     content: "Among Beacon ,Face and QR  one should be enabled",
        //     color: "red",
        //   });
        // } else {
        this.edit_member();
        // }
      } else if (
        this.$refs.form.validate() &&
        this.switch_scananywhere != "radio-1" &&
        this.switch_scananywhere == "radio-2" &&
        this.filterlocation != ""
      ) {
        // if (
        //   this.switch_beacon == false &&
        //   this.switch_face == false &&
        //   this.switch_QR == false
        // ) {
        //   this.$store.commit("snackbar/showMessage", {
        //     content: "Among Beacon ,Face and QR  one should be enabled",
        //     color: "red",
        //   });
        // } else {
        this.edit_member();
        // }
      } else {
        this.$emit("clicked", 0);
      }
    },

    async edit_member() {
      this.isLoading = true;
      try {
        var inputParams = {
          user_name: this.member_name,
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          employee_id: this.member_id,
          user_contact_number:
            this.country_code == null ||
            this.country_code == undefined ||
            this.country_code == ""
              ? undefined
              : this.contact_number,
          user_country_code:
            this.contact_number == null ||
            this.contact_number == undefined ||
            this.contact_number == ""
              ? undefined
              : this.country_code == null
              ? undefined
              : this.country_code.toString().includes("+") == false
              ? "+" + this.country_code
              : this.country_code,
          user_email_id:
            this.email == "" || this.email == null || this.email == undefined
              ? this.email
              : this.email.toLowerCase().trim(),
          is_whatsapp:
            this.switch_whatsapp == true ? false : this.switch_whatsapp,
          is_beacon_enabled: this.switch_beacon,
          is_qr_enabled: this.switch_QR,
          // "name" : "is_face_enabled",
          is_face_enabled: this.switch_face,
          is_proxy_enabled: this.switch_proxy,
          is_scan_any_where:
            this.switch_scananywhere == "radio-1" ? true : false,
          // is_reswipe_enabled: this.switch_reswipe,
          user_id: this.user_id,
          workshift_id: this.shiftName,
          location_id:
            this.switch_scananywhere == "radio-1"
              ? ""
              : this.switch_scananywhere == "ANY_LOCATIONS"
              ? "ANY_LOCATIONS"
              : this.switch_scananywhere == "radio-2"
              ? this.filterlocation
              : "",
          date_of_birth:
            this.birthdate == "" ? undefined : this.formatDate(this.birthdate),
          designation: this.DesginationName,
          employee_type: this.employeetypeName,
          home_location_id: this.homeLoaction.location_id,
          home_location_name: this.homeLoaction.location_name,
          action_taken_by: this.$store.getters.get_useremail,
          is_tracking_mandatory: this.switch_livetracking,
          is_simple_scan_enabled: this.switch_simplescan,
          joining_date:
            this.joindate == "" ? undefined : this.formatDate(this.joindate),
        };

        for (var [key, value] of Object.entries(inputParams)) {
          if (value == null || value == undefined) {
            delete inputParams[key];
          }
        }

        await API.graphql(
          graphqlOperation(UpdateMemberDetails, {
            input: inputParams,
          })
        ).then((response) => {
          this.$store.commit("snackbar/showMessage", {
            content: response.data.UpdateMemberDetails.Message,
            color: "green",
          });
          this.isLoading = false;
          this.$emit("clicked", 1);
        });
      } catch (e) {
        console.log("e", e);
        this.$store.commit("snackbar/showMessage", {
          content: e.errors[0].message,
          color: "red",
        });
        this.isLoading = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    newdateformate(inputDate) {
      const [day, month, year] = inputDate.split("/");
      const newDate = new Date(`${year}-${month}-${day}`);
      const convertedDate = newDate.toISOString().split("T")[0];
      // console.log(convertedDate);
      return convertedDate;
    },
    close_dialog() {
      this.$refs.form.resetValidation();
      this.member_id = "";
      this.member_name = "";
      this.email = "";
      this.country_code = "";
      this.contact_number = "";
      this.switch_reswipe = false;
      this.switch_whatsapp = false;
      this.switch_beacon = false;
      this.switch_QR = false;
      (this.switch_simplescan = false), (this.switch_face = false);
      this.switch_scananywhere = false;
      this.switch_proxy = false;
      this.$emit("close_edit_user");
    },
  },
};
</script>

<style>
.v-main__wrap {
  overflow-y: hidden;
}
</style>
